.maki.card {
  background-color: transparent;
  color: $color-accent;

  .card-body {
    padding: $card-spacer-x;
    background: $black;
    text-align: left;

    .card-title {
      span.title {
        font-weight: 900;
        // font-family: $font-accent;
        font-size: 1.2rem;
        text-transform: none;
        // max-width: 310px;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: smaller;
        margin-bottom: 5px;
      }
      span.price {
      }
    }
  }
}

.table {
  @extend .table-dark;
}

.card,
.navbar-light .offcanvas-collapse {
  color: $black;
}
