.prefooter-wrapper {
  color: $black;
  background-color: $white;

  .prefooter-text {
    padding-left: 0;
    padding-right: 0;
    align-self: center;
    @include media-breakpoint-down(lg) {
      margin-bottom: 2rem;
    }
  }

  .prefooter-icon-list {
    align-items: center;
    flex-wrap: wrap;

    .row {
      justify-content: space-around;

      @include media-breakpoint-down(xs) {
        row-gap: 20px;
      }

      div {
        text-align: center;
        @include media-breakpoint-up(xxl) {
          flex: 0 0 16.6666666667% !important;
          max-width: 16.6666666667% !important;
        }
      }
    }

    img {
      max-width: 100px !important;
      max-height: 100px;
      aspect-ratio: 1;
    }
  }
}

#footer {
  color: $color-accent;
  min-height: unset;
  a {
    color: $color-accent;
  }
}
