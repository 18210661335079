body {
}

.product-content {
  background: transparent;
}

@media (min-width: 1200px) {
  .container {
    max-width: 70%;
  }
  .product-detail .container {
    max-width: 80%;
  }
}

.w-40 {
  width: 80%;
  @include media-breakpoint-up(md) {
    width: 33.3333333333%;
  }
}

.product-content h1 {
  @include media-breakpoint-up(md) {
    //@extend .text-truncate;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
legend,
b {
  //text-transform: uppercase;
  font-weight: 900 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
  text-transform: uppercase;
}

#root {
  padding-top: 7rem;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm;
  }
}
.card.bg-light {
  background-color: $gray-800 !important;
  color: $white;
}

// JUMBOTRON
.header-jumbotron {
  // background-color: $dark;
  // color: $white;
  background-image: url('./assets/images/header1.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translateY(-10rem);
  height: 100vh;
  @include media-breakpoint-down(sm) {
    transform: translateY(-6rem);
    height: 60vh;
  }
}

.offcanvas-collapse {
  @include media-breakpoint-up(md) {
    top: 8rem;
  }
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.when-valid {
  background: rgba($darkgray, 0.9);
  transition: all 0.3s;
  .modal & {
    background-color: inherit;
  }
}

.sticky-bottom.when-valid {
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

.masonry {
  row-gap: 2rem;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// Loading Spinner
.loading-spinner-content {
  background: transparent !important;
}
#initial-spinner.spinner-boarder.text-dark {
  color: $color-accent !important;
}

// buttons

.btn {
  text-transform: uppercase;
}

.btn-primary {
  background-color: transparent !important;
  border-color: $color-accent;
  font-weight: 900;
  color: $color-accent;
  &:hover,
  &:active {
    background-color: $color-accent !important;
    color: $white;
  }
}

.shoppingcart-finisher,
.sticky-bottom.when-valid {
  .btn-primary {
    background-color: transparent !important;
    border-color: $white;
    font-weight: 900;
    color: $white;
    &:hover,
    &:active {
      background-color: $color-accent !important;
      color: $white;
    }
  }
  &:not(.is-valid) {
    background-color: $gray-800;
  }
}

@import './footer.scss';
@import './maki.scss';
@import './navigation.scss';
@import './nigiri.scss';
@import './product-detail.scss';
