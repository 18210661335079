.product-detail-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  //justify-content: center;

  .product-supertitle {
    display: none;
  }
  .product-title {
    display: block;
    text-transform: uppercase;
    //margin-bottom: 1rem;
    letter-spacing: 2px;
  }
  .product-subtitle {
    font-size: $h1-font-size;
    font-weight: 900;
    font-family: $font-main !important;
    margin-bottom: 1rem;
  }
}
