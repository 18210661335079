.navbar-light .offcanvas-collapse,
.navbar-dark .offcanvas-collapse {
  background: transparent;

  &.offcanvas-collapse-right {
    background: rgba($color: $black, $alpha: 0.9);
    h5 {
      color: white;
    }
  }

  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
  }
}

.navbar-dark {
  // background: transparent !important;
  // transition: background 0.2s;
  // background-color: rgba($color: $white, $alpha: 0.9) !important;
  // body[data-scrolled] & {
  //   background-color: $black !important;
  // }
}

.navbar-dark .offcanvas-collapse {
  .navbar-nav .nav-item .nav-link {
    //font-family: 'Visual-Regular', $font-family-sans-serif;
    //text-transform: uppercase;
    font-size: 1.3rem;
    background-color: $color-accent;
  }
}

#header > .navbar {
  height: auto;
  background-color: $color-dark;
  @include media-breakpoint-down(sm) {
    padding-top: 0.35rem;
    height: $calculated-navbar-height-sm;
  }

  .main-menu {
    flex-basis: 0 !important;
    // flex-grow: 1 !important;
  }

  .navbar-brand {
    padding: 0;
    justify-content: center;
    color: $color-accent;
    font-size: 2rem;
  }

  .offcanvas-collapse-left {
    .navbar-nav {
      background-color: rgba($color-dark, 0.8);
      border-radius: 0px $border-radius $border-radius 0px;

      font-family: $font-accent;

      .nav-item {
        .nav-link {
          background-color: transparent;
          color: $color-accent;
          padding: 1.5rem;
          text-transform: uppercase;
          // font-stretch: 150%;
          letter-spacing: 3px;
        }
      }
    }
  }

  .hamburger {
    background-color: transparent !important;
  }
}

body[data-scrolled] {
  .navbar {
    // height
  }
}
